import React, { useContext, useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import { Link } from 'react-router-dom';
import { UserContext, emptyUser } from '../../User';

import './Logout.scss';

const Logout = () => {
  const className = 'c-Logout';
  const { setCognitoUser } = useContext(UserContext);
  const [message, setMessage] = useState('Should not see this');

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        setCognitoUser(emptyUser);
        setMessage('You have logged out successfully');
      })
      .catch(() => setMessage('An error occurred while logging out'));
  }, [setCognitoUser]);

  return (
    <div className={className}>
      <div className={`${className}__container`}>
        <div className={`${className}__text`}>{message}</div>
        <div className={`${className}__button_container`}>
          <Link to={{ pathname: '/' }}>
            <button type='button'>Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Logout;

import React from 'react';
import Countdown from '../../components/Countdown';
import anaPicture from '../../assets/ana.jpg';
import './AnaCountdown.scss';

const AnaCountdown = () => {
  const className='c-AnaCountdown';
  const ARRIVAL_TIME = new Date(2020, 0, 2, 23, 30);

  return (
    <div className={`${className}`}>
      <img src={anaPicture} alt="Ana" className={`${className}__image`} />
      <div className={`${className}__text`}>
        <p>Time till Ana is back:</p>
        <Countdown endTime={ARRIVAL_TIME} completeMessage="She's here!"/>
      </div>
    </div>
  )
}

export default AnaCountdown;

import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { UserContext } from '../../User';
import { routes } from '../../routes';
import './Login.scss';

type Props = {}

const Login = (props: Props & RouteComponentProps) => {
  const className = 'c-Login';
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setCognitoUser } = useContext(UserContext);

  const handleLoginSubmit = () => {
    Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoUser(user);
          props.history.push(routes.NewPassword.path);
        } else {
          setCognitoUser(user);
          props.history.push(routes.Info.path);
        }
      })
      .catch((error) => {
        console.error('password', error.message);
      });

  }

  return (
    <div className={className}>
      <div className={`${className}__title`}>
        Login
      </div>
      <div className={`${className}__field_label`}>
        <label htmlFor='username'>
          Username
          <input
            className={`${className}__input`}
            type='text'
            onChange={(e) => {
              setUsername(e.currentTarget.value);
            }}
          />
        </label>
      </div>
      <div className={`${className}__field_label`}>
        <label htmlFor='password'>
          Password
          <input
            className={`${className}__input`}
            type='password'
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
          />
        </label>
      </div>
      <div>
        <button
          type='submit'
          tabIndex={0}
          onClick={handleLoginSubmit}
        >
          Login
        </button>
      </div>
    </div>
  )
}

export default Login;

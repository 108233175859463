import {
  JsLogo,
  HTMLLogo,
  ReactLogo,
  CSSLogo,
  AngularLogo,
  MySQLLogo,
  JavaLogo,
  AWSLogo,
  ReduxLogo,
  HighchartsLogo,
  PythonLogo,
  SharepointLogo,
  TableauLogo
} from "../components/Icons";

const technologyDetails = [
  {
    name: 'Javascript',
    url: 'https://www.javascript.com/',
    icon: JsLogo,
    ability: 'proficient',
  },
  {
    name: 'React',
    url: 'https://reactjs.org/',
    icon: ReactLogo,
    ability: 'proficient',
  },
  {
    name: 'HTML',
    url: 'https://www.w3.org/html/',
    icon: HTMLLogo,
    ability: 'proficient',
  },
  {
    name: 'CSS',
    url: 'https://en.wikipedia.org/wiki/Cascading_Style_Sheets',
    icon: CSSLogo,
    ability: 'proficient',
  },
  {
    name: 'Angular',
    url: 'https://angular.io/',
    icon: AngularLogo,
    ability: 'proficient',
  },
  {
    name: 'MySQL',
    url: 'https://www.mysql.com/',
    icon: MySQLLogo,
    ability: 'proficient',
  },
  {
    name: 'Java',
    url: 'https://www.java.com/en/',
    icon: JavaLogo,
    ability: 'experienced',
  },
  {
    name: 'AWS',
    url: 'https://aws.amazon.com/',
    icon: AWSLogo,
    ability: 'experienced',
  },
  {
    name: 'Redux',
    url: 'https://redux.js.org/',
    icon: ReduxLogo,
    ability: 'experienced',
  },
  {
    name: 'Highcharts',
    url: 'https://www.highcharts.com/',
    icon: HighchartsLogo,
    ability: 'experienced',
  },
  {
    name: 'Python',
    url: 'https://www.python.org/',
    icon: PythonLogo,
    ability: 'experienced',
  },
  {
    name: 'Sharepoint',
    url: 'https://products.office.com/en-us/sharepoint/collaboration',
    icon: SharepointLogo,
    ability: 'experienced',
  },
  {
    name: 'Tableau',
    url: 'https://www.tableau.com/',
    icon: TableauLogo,
    ability: 'experienced',
  }
];

export default technologyDetails;

import React, { ReactNode } from 'react';
import EmploymentCard from '../../components/EmploymentCard';
import './Info.scss';
import employmentDetails from '../../data/employment';
import technologyDetails from '../../data/technologies';
import TechnologyItem from '../../components/TechnologyItem';

const Info = () => {
  const className = 'c-Info';
  // const proficientTechnologies = ['Javascript', 'HTML', 'CSS', 'React', 'Angular', 'SQL', 'Git'];
  // const experiencedTechnologies = ['Java', 'AWS', 'Sharepoint', 'Python', 'Highcharts', 'Tableau', 'Redux'];

  const proficientList: ReactNode[] = [];
  // proficientTechnologies.forEach((technology) => proficientList.push(<li>{technology}</li>));

  const experiencedList: ReactNode[] = [];
  // experiencedTechnologies.forEach((technology) => experiencedList.push(<li>{technology}</li>));
  const otherTechList = [];

  const employmentList: ReactNode[] = [];
  employmentDetails.forEach((employment) =>
    employmentList.push(<EmploymentCard employmentDetails={employment} key={`employment_${employment.companyName}`} />))

  // const technologyList = [];
  technologyDetails.forEach((technology) => {
    switch(technology.ability) {
      case 'proficient':
        proficientList.push(<TechnologyItem technologyDetails={technology} key={`employment_${technology.name}`} />);
        break;
      case 'experienced':
        experiencedList.push(<TechnologyItem technologyDetails={technology} key={`employment_${technology.name}`} />);
        break;
      case 'other':
      default:
        otherTechList.push(<TechnologyItem technologyDetails={technology} key={`employment_${technology.name}`} />);
        break;
    }
  });

  return (
    <div className={className}>
      <p className={`${className}__header`}>
        {'Technologies'}
      </p>
      <p className={`${className}__sub-header`}>
        Proficient:
      </p>
      <div>
        {proficientList}
      </div>
      <p className={`${className}__sub-header`}>
        Other:
      </p>
      <div>
        {experiencedList}
      </div>
      <p className={`${className}__header`}>
        Employment:
      </p>
      <div className={`${className}__employment-section`}>
        {employmentList}
      </div>
      <p>
        {`EDUCATION
          B.Sc Computer Science and Software Engineering, NUI Maynooth – 2009 - 2013`}
      </p>
    </div>
  )
}

export default Info;

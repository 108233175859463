import {
  FacebookLogo,
  SinglePointLogo,
  VodafoneLogo,
  ZurichLogo,
} from '../components/Icons';

  const employmentDetails = [
    {
      companyName: 'Singlepoint Solutions',
      title: 'Technology Consultant',
      startDate: 'Nov 2014',
      finishDate: null,
      text: 'Worked for various clients throughout my time at Singlepoint.',
      projects: [{
        title: 'BlueGrizzly Insurance Wallet',
        description: 'A new platform through which users can buy & renew their insurance policies, across various types of insurance from a variety of providers',
        technologies: ['React', 'Flow', 'Formik', 'Yup', 'Storybook', 'Sass', 'Jest', 'Git', 'Lerna']
      }],
      colour: '#F68B1F',
      logo: SinglePointLogo,
      website: 'https://www.singlepoint.ie/',
    },
    {
      companyName: 'Zurich Insurance',
      title: 'Lead Developer',
      startDate: 'Mar 2018',
      finishDate: 'Mar 2019',
      text: 'Working as a Team Lead/Lead Developer on Angular project creating a web form for use by brokers for pension applications to replace the paper process.',
      projects: [{
        title: 'Pensions Digitisation',
        description: 'Creating a digital version of Zurich\'s pension application form for brokers use. Built as a generic form builder',
        technologies: ['Angular', 'Typescript', 'Bootstrap', 'RxJs', 'Karma']
      }],
      colour: '#000068',
      logo: ZurichLogo,
      website: 'https://www.zurich.ie/',
    },
    {
      companyName: 'Facebook',
      title: 'Technology Consultant',
      startDate: 'May 2015',
      finishDate: 'Dec 2017',
      text: `Worked on various projects during my time in Facebook.`,
      projects: [{
        title: 'Data pipeline management & DQ',
        description: 'Using internal interface to manage financial data pipelines, maintaining the pipelines & creating DQ processes and data vizualisations',
        technologies: ['SQL', 'Python', 'Highcharts', 'Tableau'],
      }, {
        title: 'CRM',
        description: 'Worked on maintaining & improving existing internal ad revenue CRM tool',
        technologies: ['React', 'JS', 'PHP', 'Hack', 'SQL', 'Flow', 'Highcharts', 'Redux', 'Mercurial']
      }, {
        title: 'HyperBunny Chrome Extension',
        description: 'Chrome Extension for internal use allowing users to quickly open internal ids in the appropriate tool',
        technologies: ['JS', 'PHP', 'SQL', 'Highcharts']
      }, {
        title: 'Odyssey',
        description: 'React based CRM tool for gathering internal employee feedback and suggestions',
        technologies: ['React', 'JS', 'Flux', 'Flow', 'Mercurial']
      }],
      colour: '#4267b2',
      logo: FacebookLogo,
      website: 'https://www.facebook.com/',
    },
    {
      companyName: 'Vodafone',
      title: 'Technology Consultant',
      startDate: 'Nov 2014',
      finishDate: 'May 2015',
      text: `Worked on internal project tracking tool`,
      projects: [{
        title: 'IT Change Portal',
        description: 'Internal portal tool for tracking IT projects',
        technologies: ['Sharepoint', 'JS', 'KnockoutJS']
      }],
      colour: '#E60000',
      logo: VodafoneLogo,
      website: 'https://n.vodafone.ie/en.html',
    }
  ];

  export default employmentDetails;

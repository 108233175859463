import React, { ReactNode } from 'react';
import ProjectInfo from '../ProjectInfo';
import { ProjectDetail } from '../ProjectInfo/ProjectInfo';
import './EmploymentCard.scss';

export type EmploymentDetail = {
  colour: string;
  website: string;
  logo: string;
  companyName: string;
  title: string;
  startDate: string;
  finishDate: string | null;
  text: string;
  projects: ProjectDetail[];
}

type Props = {
  employmentDetails: EmploymentDetail
}

const EmploymentCard = (props: Props) => {
  const className = 'c-EmploymentCard';
  const { employmentDetails } = props;

  const projectList: ReactNode[] = [];
  employmentDetails.projects.forEach((project) => {
    projectList.push(<div className={`${className}__project`} key={`project_${project.title}`}><ProjectInfo project={project} /></div>);
  })

  return (
    <div className={className} style={{borderColor: employmentDetails.colour}}>

      <div className={`${className}__header`}>
        <div style={{backgroundColor: employmentDetails.colour}} className={`${className}__companyStyleArea`}>
          <a
            href={employmentDetails.website}
            style={{textDecoration: 'none'}}
          >
            <div className={`${className}__icon-container`}>
              <img src={employmentDetails.logo} className={`${className}__icon`} alt="logo" />
            </div>
            <p className={`${className}__company-name`}>
              {employmentDetails.companyName}
            </p>
          </a>
        </div>

        <div className={`${className}__sub-header`}>
          <div className={`${className}__title`}>
            <p>
              {employmentDetails.title}
            </p>
          </div>
          <div className={`${className}__employment-period`}>
            <p>
              {`${employmentDetails.startDate} - ${employmentDetails.finishDate || 'Present'}`}
            </p>
          </div>
        </div>
      </div>

      <div className={`${className}__body`}>
        {employmentDetails.text}
        <div>
          <div className={`${className}__project-title`}>
            Projects:
          </div>
          <div>
            {projectList}
          </div>
        </div>
      </div>

    </div>
  );
}

export default EmploymentCard;

import React, { useEffect, useState } from 'react';

interface Props {
  completeMessage: String,
  endTime: Date
}

const Countdown = (props: Props) => {
  const { completeMessage, endTime } = props;
  const [time, setTime] = useState();

  useEffect(() => {
    const calculateTimeDifference = () => {
      const now = new Date();
      const diff = endTime.getTime() - now.getTime();
      if (diff > 0) {
        let msec = diff;
        const hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        const mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        const ss = Math.floor(msec / 1000);
        msec -= ss * 1000;

        return `${hh} Hours, ${mm} Minutes, ${ss} Seconds`;
      }

      return completeMessage;
    }

    const intervalID = setInterval(
      () => setTime(calculateTimeDifference()),
      1000
    );

    return () => clearInterval(intervalID);
  }, [endTime, time]);

  return (
    <div>
      {time}
    </div>
  )
}

export default Countdown;

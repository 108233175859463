import React from 'react';
import Countdown from '../../components/Countdown';
import amsterdam from '../../assets/amsterdam.jpg';
import ReactWeather from 'react-open-weather';
import 'react-open-weather/lib/css/ReactWeather.css';
import './Amsterdam.scss';

const Amsterdam = () => {
  const className='c-Amsterdam';
  const FLIGHT_TIME = new Date(2019, 6, 19, 15, 30);

  return (
    <div className={`${className}`}>
      <img src={amsterdam} alt="amsterdam" className={`${className}__image`} />
      <div className={`${className}__text`}>
        <p>Time to Amsterdam:</p>
        <Countdown endTime={FLIGHT_TIME} completeMessage="Time has passed"/>
      </div>
      <div className={`${className}__weather`}>
        <ReactWeather
          forecast="5days"
          apikey="d052674250e8417ba80192557191807"
          type="city"
          city="Amsterdam"
        />
      </div>
      <div className={`${className}__text`}>
        Accomodation Address:
        <p>Tweede van Swindenstraat 11D, Amsterdam, Noord-Holland 1093 VE, Netherlands</p>
      </div>
    </div>
  )
}

export default Amsterdam;

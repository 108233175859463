import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { routes } from '../../routes';
import { UserContext } from '../../User';

type Props = {
  component: any;
}

const PrivateRoute = ({ component: Component, ...rest }: Props & RouteProps) => {
  const { cognitoUser } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={props => cognitoUser.hasOwnProperty('username') ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: routes.Home.path, state: { from: props.location }}} />
      )}
    />
  );
}

export default PrivateRoute;

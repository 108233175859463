import React, { ReactNode } from 'react';
import './ProjectInfo.scss';

export type ProjectDetail = {
  title: string;
  description: string;
  technologies: string[];
}

type Props = {
  project: ProjectDetail
}

const ProjectInfo = (props: Props) => {
  const className = 'c-ProjectInfo';
  const { project } = props;

  const technologies: ReactNode[] = [];
  project.technologies.forEach((technology) => {
    technologies.push(<div className={`${className}__technology`} key={`technology_${technology}`}>{technology}</div>);
  });

  return (
    <div className={className}>
      <div className={`${className}__title-container`}>
        <div className={`${className}__title`}>{project.title}</div>
      </div>
      <div className={`${className}__description`}>{project.description}</div>
      <div className={`${className}__technologies-section`}>
        <div className={`${className}__technologies-title`}>Technologies:</div>
        <div className={`${className}__technologies-container`}>{technologies}</div>
      </div>
    </div>
  )
}

export default ProjectInfo;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../User';
import { routes } from '../../routes';
import './Header.scss';

const Header = () => {
  const className="c-App-header";
  const { cognitoUser } = useContext(UserContext);

  return (
    <div className={className}>
      <div className={`${className}__title`}>
        {'Colin McDermott'}
      </div>

      {cognitoUser.hasOwnProperty('username')
        ? (
          <div className={`${className}__logout`}>
            <Link to={routes.Logout.path} className={`${className}__logout_link`}>
              <div
                className={`${className}__logout_button`}
              >
                Logout
              </div>
            </Link>
          </div>
        ) : (
          <div className={`${className}__login`}>
            <Link to={routes.Login.path} className={`${className}__login_link`}>
              <div
                className={`${className}__login_button`}
              >
                Login
              </div>
            </Link>
          </div>
      )}
    </div>
  );
}

export default Header;

import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { UserContext } from '../../User';
import { routes } from '../../routes';
import './NewPassword.scss';

type Props = {}

const NewPassword = (props: Props & RouteComponentProps) => {
  const className='c-NewPassword';
  const { cognitoUser, setCognitoUser } = useContext(UserContext);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const completeNewPassword = async () => {
    if (newPassword === confirmPassword) {
    Auth.completeNewPassword(cognitoUser, newPassword, {})
      .then((user) => {
        // at this time the user is logged in if no MFA required
        setCognitoUser(user);
        props.history.push(routes.Info.path);
      })
      .catch((error) => {
        console.error('error', error.message);
      });
    } else {
      console.error('Passwords do not match');
    }
  };

  return (
    <div className={className}>
      <div className={`${className}__title`}>
        Change your Password
      </div>
      <div className={`${className}__field_label`}>
        <label htmlFor='newPassword'>
          New Password
          <input
            className={`${className}__input`}
            type='password'
            onChange={(e) => {
              setNewPassword(e.currentTarget.value);
            }}
          />
        </label>
      </div>
      <div className={`${className}__field_label`}>
        <label htmlFor='confirmPassword'>
          Confirm new Password
          <input
            className={`${className}__input`}
            type='password'
            onChange={(e) => {
              setConfirmPassword(e.currentTarget.value);
            }}
          />
        </label>
      </div>
      <div>
        <button
          type='submit'
          tabIndex={0}
          onClick={completeNewPassword}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default NewPassword;


import React, { useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';

type Props = {
  children: any;
}

type User = {
  cognitoUser: any,
  setCognitoUser: any,
  cognitoAccessTokenKey: any,
  setCognitoAccessTokenKey: any,
  getUser: any
};

export const emptyUser = {
  cognitoUser: {},
  setCognitoUser: (value: any) => value,
  cognitoAccessTokenKey: '',
  setCognitoAccessTokenKey: (value: any) => value,
  getUser: () => {}
};

export const UserContext = React.createContext(emptyUser);



const User = ({ children }: Props) => {
  const [cognitoUser, setCognitoUser] = useState(emptyUser);
  const [cognitoAccessTokenKey, setCognitoAccessTokenKey] = useState('');

  const getUser = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCognitoUser(user);
      })
      .catch(() => {
        setCognitoUser(emptyUser);
      });
  };

  useEffect(() => {
    // eslint-ignore-next-line
    getUser();
  }, []);

  const value = {
    setCognitoUser,
    cognitoUser,
    cognitoAccessTokenKey,
    setCognitoAccessTokenKey,
    getUser
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default User;

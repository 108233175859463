import React from 'react';
import './TechnologyItem.scss';

export type TechnologyDetail = {
  icon: string;
  url: string;
  name: string;
}

type Props = {
  technologyDetails: TechnologyDetail;
}

const TechnologyItem = (props: Props) => {
  const className = 'c-TechnologyItem';
  const { technologyDetails } = props;
  return (
    <div className={className}>

      <div className={`${className}__icon-container`}>
        <img src={technologyDetails.icon} className={`${className}__icon`} alt="logo" />
      </div>

      <div className={`${className}__text`}>
        <a href={technologyDetails.url}>{technologyDetails.name}</a>
      </div>
    </div>
  );
}

export default TechnologyItem;

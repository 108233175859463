import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { ProfilePicture } from '../../components/Icons';
import './Home.scss';

const Home = () => {
  const className='c-Home';

  return (
    <div className={className}>
      <div className={`${className}__content_container`}>
        <div className={`${className}__image_container`}>
          <img src={ProfilePicture} alt='colin' className={`${className}__image`} />
        </div>
        <div className={`${className}__name`}>Colin McDermott</div>
        <div className={`${className}__contact_details_container`}>
          <div className={`${className}__email`}>
            <a href='mailto: colinmcd@live.ie'>
              <FontAwesomeIcon icon={faEnvelope} color='#eee' className={`${className}__email_icon`} />
            </a>
          </div>
          <div className={`${className}__linkedin`}>
            <a href='https://www.linkedin.com/in/colin-mcdermott-b980143b'>
              <FontAwesomeIcon icon={faLinkedin} color='#eee' className={`${className}__linkedin_icon`} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;

import React from 'react';
import './App.css';
import Header from './components/Header';

type Props = {
  children?: any
};

function App({children}: Props) {
  return (
    <div className="App">
      <Header />
      <div className="c-App__content">{children}</div>
    </div>
  );
}

export default App;

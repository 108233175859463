import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import App from './App';
import Home from './pages/Home';
import Login from './pages/Login';
import Info from './pages/Info';
import NewPassword from './pages/NewPassword';
import Logout from './pages/Logout';
import Amsterdam from './pages/Amsterdam';
import AnaCountdown from './pages/AnaCountdown';

export const routes = {
  Home: {
    path: '/'
  },
  Info: {
    path: '/info'
  },
  Login: {
    path: '/login'
  },
  NewPassword: {
    path: '/newpassword'
  },
  Logout: {
    path: '/logout'
  },
  Amsterdam: {
    path: '/amsterdam'
  },
  AnaCountdown: {
    path: '/anaCountdown'
  }
};

const WebsiteRouter = () => (
  <Switch>
    <Route>
      {() => (
        <App>
          <Switch>
            <Route exact path={routes.Home.path} component={Home} />
            <PrivateRoute exact path={routes.Info.path} component={Info} />
            <Route exact path={routes.Login.path} component={Login} />
            <Route exact path={routes.NewPassword.path} component={NewPassword} />
            <Route exact path={routes.Logout.path} component={Logout} />
            <Route exact path={routes.Amsterdam.path} component={Amsterdam} />
            <Route exact path={routes.AnaCountdown.path} component={AnaCountdown} />
          </Switch>
        </App>
      )}
    </Route>
  </Switch>
);

export default WebsiteRouter;
